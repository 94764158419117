/** WISIWIX PERSO **
------------------------------------------------------------
------------------------------------------------------------
#cke_18, #cke_26, #cke_34, #cke_39, #cke_88, #cke_91,
#cke_54, #cke_55, #cke_56, #cke_57, #cke_63, #cke_64, #cke_69, #cke_70, #cke_71,
#cke_74, #cke_75, #cke_77, #cke_78, #cke_79, #cke_80, #cke_81, #cke_82, #cke_83 {
  display: none;
}*/
/** Tableau récap d'un compte CT **
------------------------------------------------------------
------------------------------------------------------------*/
.select-display{
  width: 15px!important;
  height: 16px !important;
  padding: 0px 10px !important;
}

/** Form edit tache **
------------------------------------------------------------
------------------------------------------------------------*/
#tache_edit_nom:hover{
  border: 1px solid #dee2e6;
}

#tache_edit_priorite label {
  min-width: 15px;
  max-width: 15px;
  height: 15px;
  border-radius: 25px;
  position: relative;
  right: 8px;
  color: transparent;
}

#tache_edit_priorite input{
  width: 15px;
  height: 15px;
  position: relative;
  left: 7px;
}

#tache_edit_priorite input:checked{
  min-width: 25px;
  height: 25px;
  left: 13px;
}

#tache_edit_priorite input:checked + label{
  min-width: 25px;
  max-width: 25px;
  height: 25px;
  right: 12px;
}

form[name="tache_edit"] .photoDeProfil input{
  position: relative;
  z-index: 1;
  opacity: 0;
  height: 30px;
  left: 15px;
}

.document.tache, .note.tache {
  display: none;
}

.staticFooterMenu {
  position: fixed;
  top: 90%;
  height: 10%;
  width: 84%;
  z-index: 1;
}

#affichage-select-statut{
  top: -34px;
  margin-left: 2%;
  padding: 5px;
}

#affichage-select{
  top: -34px;
  margin-left: 2%;
  padding: 5px;
  margin-bottom: -34px;
}

form[name="tache_edit"] .statut.tache {
  margin-bottom: -34px;
}

.formTache .txtDescription img{
  width: auto;
  max-width: 100%;
}

/** Form add new tache **
------------------------------------------------------------
------------------------------------------------------------*/
form[name="tache"] label {
  display: block;
}

form[name="tache"] label[for="tache_nom"]{
  display: none;
}

#tache_nom:hover{
  border: 1px solid #dee2e6;
}

#tache_priorite label {
  min-width: 15px;
  max-width: 15px;
  height: 15px;
  border-radius: 25px;
  position: relative;
  right: 8px;
  color: transparent;
}

#tache_priorite input{
  width: 15px;
  height: 15px;
  position: relative;
  left: 7px;
}

#tache_priorite input:checked{
  min-width: 25px;
  height: 25px;
  left: 13px;
}

#tache_priorite input:checked + label{
  min-width: 25px;
  max-width: 25px;
  height: 25px;
  right: 12px;
}

#tache_facturation, #tache_pole {
  margin-left: -13px;
}

#tache_facturation label, #tache_pole label {
  padding: 0.5em;
  font-weight: bold;
  border-radius: 5px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#tache_facturation label {
  background-color: #a2a2a2;
  color: white;
}
#tache_pole label {
  border: solid 1px #a2a2a2;
  color: #a2a2a2;
}

#tache_facturation input,
#tache_pole input{
  width: 100px;
  height: 30px;
  position: relative;
  left: 14px;
  opacity: 0;
}

#tache_facturation input:checked + label{
  background-color: #00E1FF;
  color: black;
}

#tache_pole input:checked + label{
  border-color: #0E4BFB;
  color: #0E4BFB;
}

form[name="tache"] .photoDeProfil input{
  position: relative;
  z-index: 1;
  opacity: 0;
  height: 30px;
  width: 40px;
}

form[name="tache"] .photoDeProfil :checked + label img{
  border-color: #0042ff;
}

form[name="tache"] .statut.tache {
  margin-bottom: -34px;
}

/** Form new ticket **
------------------------------------------------------------
------------------------------------------------------------*/
#demande_pole input{
  height: 30px;
  position: relative;
  left: 14px;
  opacity: 0;
}

#demande_pole label{
  padding: 0.5em;
  font-weight: bold;
  border-radius: 5px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#demande_pole label{
  border: solid 1px #a2a2a2;
  color: #a2a2a2;
}

/** Form edit achatCT **
------------------------------------------------------------
------------------------------------------------------------*/
.form.edit_achat{
  height: 550px;
}

form[name="achat_ct"] label {
  display: block;
}

form[name="achat_ct"] .photoDeProfil input {
  position: relative;
  z-index: 1;
  opacity: 0;
  height: 30px;
  width: 40px;
}

form[name="achat_ct"] .photoDeProfil label img {
  margin-right: 10px;
}

form[name="achat_ct"] .photoDeProfil :checked + label img {
  border-color: #0042ff;
}

.interlocuteur .photoDeProfil label{
  margin-top: -40px;
}

.facture.achatCT{
  border: dashed 1px #c0c0c0;
}

/** Form new achatCT **
------------------------------------------------------------
------------------------------------------------------------*/
.form.new_achat{
  height: 550px;
}

/** Form edit site **
------------------------------------------------------------
------------------------------------------------------------*/
.form.edit_site, .form.new_site{
  margin-bottom: 100px;
}

form[name="societe"] .photoDeProfil input{
  position: relative;
  z-index: 1;
  opacity: 0;
  height: 30px;
  width: 40px;
}

form[name="societe"] .photoDeProfil :checked + label img{
  border-color: #0042ff;
}

.commercial .photoDeProfil label {
  margin-top: -40px;
  display: block;
}

.switch {
  position: relative;
  display: inline-block;
  min-width: 66px;
  max-width: 66px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #00E1FF;
}

input:focus + .slider {
  box-shadow: 0 0 1px #00E1FF;
}

input:checked + .slider:before {
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/** Form edit utilisateur **
------------------------------------------------------------
------------------------------------------------------------*/
label[for="edit_user_form_plainPassword"],label[for="user_form_plainPassword"]  {
  display: flex;
}

/** Form edit client **
------------------------------------------------------------
------------------------------------------------------------*/
.form.edit_societe{

}

/** Form new client **
------------------------------------------------------------
------------------------------------------------------------*/
.form.new_societe{
  height: 500px;
}

/** Form edit serveur **
------------------------------------------------------------
------------------------------------------------------------*/
.form.edit_serveur input[type="color"]{
  height: 38px;
}

/** Form checklist **
------------------------------------------------------------
------------------------------------------------------------*/
.checklist .checkbox{
  min-width: 25px;
  max-width: 25px;
  min-height: 25px;
  max-height: 25px;
  z-index: 2;
  opacity: 0;
}
.checklist .label-checkbox{
  display: flex;
  justify-content: space-between;
}
.checklist .display-checkbox-c div{
  display: flex;
  color: transparent;
  align-items: center;
  justify-content: center;
  min-width: 25px;
  max-width: 25px;
  min-height: 25px;
  max-height: 25px;
  border: solid 1px black;
  position: relative;
  margin-left: -20px;
  z-index: 1;
  border-radius: 25px;
}
.checklist .display-checkbox-c div:hover{
  border: solid 1px #00E1FF;
}
.checklist :checked + .display-checkbox-c .label-checkbox{
  text-decoration: line-through;
  color: #A2A2A2;
}
.checklist :checked + .display-checkbox-c div{
  color: white;
  background: #00E1FF;
  border: solid 1px #00E1FF;
}

/** Form filtre wiki **
------------------------------------------------------------
------------------------------------------------------------*/
#formFiltreWiki #filtre_statut p {
  background-color: #a2a2a2;
  color: white;
  padding: 0.5em;
  font-weight: bold;
  border-radius: 5px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#formFiltreWiki #filtre_statut input {
  opacity: 0;
  position: relative;
}
#formFiltreWiki #filtre_statut input:checked + p {
  background-color: #00E1FF;
  color: black;
}